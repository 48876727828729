import { Reducer } from 'react';

import { Actions } from './constants';
import { ActionsType, Contain, State } from './types';

const initialState: State = {
  configModal: {
    showModal: false,
    contain: Contain.ALERT_MODAL,
    props: {},
  },
};

const uiReducer: Reducer<State, ActionsType> = (state = initialState, action): State => {
  switch (action.type) {
    case Actions.CLOSE_MODAL:
      return {
        ...state,
        configModal: {
          ...state.configModal,
          showModal: false,
        },
      };
    case Actions.OPEN_MODAL:
      return {
        ...state,
        configModal: {
          showModal: true,
          contain: action.payload.contain,
          props: action.payload.props,
        },
      };
    default:
      return state;
  }
};

export default uiReducer;
