import { Heading } from 'src/redux/headings/types';

export interface PdfProps {
  headingsProps?: Heading[];
  month?: string;
  year?: string;
  selectedRows?: any;
  tableData?: any;
  apiRef?: any;
  headings?: any;
}

export const months = [
  { value: '01', label: 'Enero' },
  { value: '02', label: 'Febrero' },
  { value: '03', label: 'Marzo' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Mayo' },
  { value: '06', label: 'Junio' },
  { value: '07', label: 'Julio' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Septiembre' },
  { value: '10', label: 'Octubre' },
  { value: '11', label: 'Noviembre' },
  { value: '12', label: 'Diciembre' },
];
