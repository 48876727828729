/* eslint-disable prettier/prettier */
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import translate from 'translate';

export const capitalizeFirstLetter = (text: string) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('es-ES', { month: 'long' });
};

export const dateAlertFormater = (dateToFormat) => {
  const timeZone = 'America/Argentina/Buenos_Aires';

  if (dateToFormat) {
    const date = parseISO(dateToFormat);
    const dateInArgentina = utcToZonedTime(date, timeZone);
    const formattedDate = format(dateInArgentina, 'dd-MM-yyyy \'a las\' HH:mm');

    return formattedDate;
  }
};


export const translateMessages = async (message: string) => {
  const errorTranslated = (await translate(`${message.replace('heading', 'rubro').replace('headings', 'rubros')}`, { to: 'es' })).replace('borrador', 'draft');
  return errorTranslated;
};

