import Joi from 'joi';

export const employeeValidation = () => {
  return Joi.object({
    firstName: Joi.string()
      .min(3)
      .max(40)
      .trim()
      .required()
      .regex(/^[A-Za-zñÑáéíóúü]+(?: [A-Za-zñÑáéíóúü]+)*$/)
      .messages({
        'string.base': 'El nombre debe contener solo letras',
        'string.empty': 'El nombre es requerido',
        'any.required': 'El nombre es requerido',
        'string.min': 'El nombre debe contener al menos 3 caracteres',
        'string.max': 'El nombre debe contener como máximo 30 caracteres',
        'string.pattern.base': 'El nombre no es válido',
      }),
    lastName: Joi.string()
      .min(3)
      .max(40)
      .trim()
      .required()
      .regex(/^[A-Za-zñÑáéíóúü]+(?: [A-Za-zñÑáéíóúü]+)*$/)
      .messages({
        'string.base': 'El apellido debe contener solo letras',
        'string.empty': 'El apellido es requerido',
        'any.required': 'El apellido es requerido',
        'string.min': 'El apellido debe contener al menos 3 caracteres',
        'string.max': 'El apellido debe contener como máximo 30 caracteres',
        'string.pattern.base': 'El apellido no es válido',
      }),
    email: Joi.string()
      .regex(/^[a-zA-Z]+\.+[a-zA-Z]+@(radiumrocket.com)$/)
      .min(20)
      .max(70)
      .trim()
      .required()
      .messages({
        'string.pattern.base': 'El email debe ser nombre.apellido@radiumrocket.com',
        'string.empty': 'El email es requerido',
        'any.required': 'El email es requerido',
        'string.min': 'El email debe contener al menos 20 caracteres',
        'string.max': 'El email debe contener como máximo 70 caracteres',
      }),
    categories: Joi.array().min(1).required().messages({
      'any.required': 'Las categorías son requeridas',
      'array.empty': 'Las categorías son requeridas',
      'array.base': 'Las categorías deben ser un array',
      'array.includesRequiredUnknowns': 'Las categorías deben contener un array con categorías',
      'array.min': 'Debe contener al menos una Categoría',
    }),
    isActive: Joi.boolean().messages({
      'boolean.base': 'El estado debe ser booleano',
    }),
  });
};
