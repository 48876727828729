import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';

import { navbarItems, navbarItemsDraft } from 'src/constants';
import { RoleType } from 'src/redux/users/types';

import styles from './navbar.module.css';

const Navbar = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [value, setValue] = React.useState<number>(0);
  const accessRole = localStorage.getItem('role');
  const listItems = accessRole === RoleType.ADMIN_AL ? navbarItems : navbarItemsDraft;

  useEffect(() => {
    {
      const itemsDisplayed = listItems.findIndex((route) => path.includes(route.path));
      setValue(itemsDisplayed);
    }
  }, [accessRole, path, listItems]);

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    setValue(value);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const items = listItems.map(
    (item) =>
      item.role.some((role) => role === accessRole) && (
        <Tab
          key={item.name}
          label={item.name}
          onClick={() => handleNavigation(item.path)}
          className={styles.tab}
        />
      ),
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="secondary"
        className={styles.tabs}
      >
        {items}
      </Tabs>
    </Box>
  );
};

export default Navbar;
