export enum Actions {
  GET_EMPLOYEES_PENDING = 'GET_EMPLOYEES_PENDING',
  GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS',
  GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR',
  ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS',
  ADD_EMPLOYEE_PENDING = 'ADD_EMPLOYEE_PENDING',
  ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR',
  EDIT_EMPLOYEE_PENDING = 'EDIT_EMPLOYEE_PENDING',
  EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS',
  EDIT_EMPLOYEE_ERROR = 'EDIT_EMPLOYEE_ERROR',
  DISABLE_EMPLOYEE_PENDING = 'DISABLE_EMPLOYEE_PENDING',
  DISABLE_EMPLOYEE_SUCCESS = 'DISABLE_EMPLOYEE_SUCCESS',
  DISABLE_EMPLOYEE_ERROR = 'DISABLE_EMPLOYEE_ERROR',
  ACTIVATE_EMPLOYEE_PENDING = 'ACTIVATE_EMPLOYEE_PENDING',
  ACTIVATE_EMPLOYEE_SUCCESS = 'ACTIVATE_EMPLOYEE_SUCCESS',
  ACTIVATE_EMPLOYEE_ERROR = 'ACTIVATE_EMPLOYEE_ERROR',
}
