import React from 'react';
import { useDispatch } from 'react-redux';

import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { closeModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

import Button from '../buttons/button';
import styles from './modal.module.css';
import { ConfirmModalProps } from './types';

const ConfirmModal = (props: ConfirmModalProps) => {
  const dispatch: AppDispatch<null> = useDispatch();
  const { title, text, action } = props;
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h2>{title}</h2>
      </div>
      <div>
        <p>{text}</p>
      </div>
      <div className={styles.button}>
        <div>
          <Button
            materialVariant={Variant.OUTLINED}
            color="primary"
            label="Cancelar"
            onClick={() => dispatch(closeModal())}
          />
        </div>
        <div>
          <Button
            materialVariant={Variant.CONTAINED}
            color="primary"
            label="Aceptar"
            onClick={() => {
              action();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
