import 'firebase/compat/auth';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';

import api from 'src/config/api';
import { loginSuccess, setAuthentication } from 'src/redux/auth/actions';
import store from 'src/redux/store';
import { RoleType } from 'src/redux/users/types';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export const getCurrentFirebaseToken = async (forceRefresh = false): Promise<string> => {
  const token = await auth.currentUser?.getIdToken(forceRefresh);
  return token || '';
};

export const tokenListener = () => {
  auth.onIdTokenChanged(async (user) => {
    try {
      if (user) {
        const {
          token,
          claims: { role, email, _id, name, isActive },
        } = await user.getIdTokenResult(true);
        store.dispatch(
          loginSuccess({
            token,
            role,
            email,
            _id,
            name,
            isActive: isActive,
          }),
        );
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('isActive', isActive);
        api.defaults.headers['token'] = token;
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('isActive');
        localStorage.removeItem('role');
        store.dispatch(
          setAuthentication({
            token: '',
            role: RoleType.ROLE_EMPTY,
            email: '',
            _id: '',
            name: '',
            isActive: false,
          }),
        );
      }
    } catch (error: any) {
      return console.error(error);
    }
  });
};

export default firebaseApp;
