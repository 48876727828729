import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { AutocompleteChip, Button, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { getCategories } from 'src/redux/categories/thunk';
import { addEmployee, editEmployee, getEmployees } from 'src/redux/employees/thunks';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { closeModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

import styles from './employee-form.module.css';
import { EmployeeFormModalProps, FormValues } from './types';
import { employeeValidation } from './validations';

const EmployeeForm = (props: EmployeeFormModalProps) => {
  const dispatch: AppDispatch<null> = useAppDispatch();

  const { id, title, limit, currentPage, isActive } = props;

  const categories = useAppSelector((state: RootState) => state.category.list);

  const employeesList = useAppSelector((state: RootState) => state.employees.list);

  const [employee, setEmployee] = useState({} as FormValues);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getEmployees(limit, currentPage, isActive));
  }, []);

  const categoriesList = useMemo(() => {
    const categoriesMapped = categories.map((category) => {
      return {
        id: category._id,
        label: category.name,
      };
    });
    return categoriesMapped;
  }, [categories]);

  useEffect(() => {
    if (id) {
      const employeeFind = employeesList.find((employee) => employee._id === id);
      setEmployee(employeeFind);
    }
  }, [employeesList, id]);

  useEffect(() => {
    if (Object.keys(employee).length) {
      reset({
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        categories: employee.categories.map((category) => {
          return {
            id: category._id,
            label: category.name,
          };
        }),
        isActive: employee.isActive,
      });
    }
  }, [employee]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      categories: [],
      isActive: true,
    },
    mode: 'onBlur',
    resolver: joiResolver(employeeValidation()),
  });

  const onSubmit = async (data) => {
    const categoriesId = data.categories.map((category) => category.id);
    const options = { id: id, body: { ...data, categories: categoriesId } };
    if (id) {
      data = {
        ...data,
        categories: categoriesId,
      };
      await dispatch(editEmployee(options));
    } else {
      data = {
        ...data,
        categories: categoriesId,
      };
      await dispatch(addEmployee(data));
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.containerInputs}>
          <div className={styles.inputs}>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="Nombre"
                name="firstName"
                type={'text'}
                error
                fullWidth
                multiline
                data-testid={'employee-input-name'}
              />
            </div>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="Apellido"
                name="lastName"
                type={'text'}
                error
                fullWidth
                multiline
                data-testid={'employee-input-lastName'}
              />
            </div>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="E-mail"
                name="email"
                type={'text'}
                error
                fullWidth
                multiline
                data-testid={'employee-input-email'}
              />
            </div>
            <div className={styles.chip}>
              <AutocompleteChip
                control={control}
                name="categories"
                items={categoriesList}
                label={'Categorías'}
                testId={'employee-categories-input'}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            testId="cancel-employee-button"
            materialVariant={Variant.OUTLINED}
            label="Cancelar"
            onClick={() => dispatch(closeModal())}
          />
          <Button
            testId="submit-employee-button"
            materialVariant={Variant.CONTAINED}
            label="Confirmar"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;
