import * as React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import { AutocompleteProps, Items } from './types';

const AutocompleteChip = <Form extends FieldValues>(
  props: AutocompleteProps<Form>,
  showError = true,
): JSX.Element => {
  const { control, name, items, placeholder, label, testId } = props;
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control });

  const handleDelete = (option: string) => {
    onChange(value.filter((options) => options.label !== option));
  };

  return (
    <div>
      <Autocomplete
        sx={{ width: 400 }}
        multiple
        value={value}
        data-testid={testId}
        id={testId}
        options={items}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={() => null}
        onChange={(e, values) => {
          onChange(values);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={placeholder}
              error={showError && !!error?.message}
              helperText={error?.message || ' '}
            />
          );
        }}
      />
      <div>
        {value.map((option: Items, index: number) => (
          <Chip
            sx={{ marginRight: '5px' }}
            variant="filled"
            label={option.label}
            key={index}
            onDelete={() => {
              handleDelete(option.label);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default AutocompleteChip;
