import { combineReducers } from 'redux';

import authReducer from '../auth/reducer';
import categoriesReducer from '../categories/reducer';
import draftsReducer from '../drafts/reducer';
import employeesReducer from '../employees/reducer';
import headingReducer from '../headings/reducer';
import historiesReducer from '../histories/reducer';
import liquidationsReducer from '../liquidations/reducer';
import paymentReducer from '../payment-details/reducer';
import uiReducer from '../ui/reducer';

const rootReducer = combineReducers({
  category: categoriesReducer,
  drafts: draftsReducer,
  employees: employeesReducer,
  headings: headingReducer,
  histories: historiesReducer,
  liquidations: liquidationsReducer,
  paymentDetails: paymentReducer,
  ui: uiReducer,
  auth: authReducer,
});

export default rootReducer;
