import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

import { Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import firebaseApp from 'src/helper/firebase';
import { useAppDispatch } from 'src/redux/store';
import { closeModal, openModal } from 'src/redux/ui/actions';
import { Contain } from 'src/redux/ui/types';
import { AppDispatch } from 'src/types';

import HeaderLogo from '../../ui/icons/header-logo';
import Navbar from '../navbar';
import styles from './header.module.css';

const Header = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch<null> = useAppDispatch();

  const handleLogout = async () => {
    try {
      navigate('/login');
      await firebaseApp.auth().signOut();
      dispatch(closeModal());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header>
      <div className={styles.container}>
        <div className={styles.brand}>
          <HeaderLogo />
          <span>Radium</span>
          <span>Accountancy</span>
        </div>
        <div className={styles.navbar}>
          <Navbar />
        </div>
        <div className={styles.logout}>
          <Button
            materialVariant={Variant.OUTLINED}
            color="primary"
            label="Salir"
            endIcon={<LogoutIcon />}
            onClick={() =>
              dispatch(
                openModal(
                  {
                    action: () => handleLogout(),
                    title: 'Cerrar sesión',
                    text: '¿Desea cerrar sesión de Radium Accountancy?',
                  },
                  Contain.CONFIRM_MODAL,
                ),
              )
            }
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
