import { action } from 'typesafe-actions';

import { Actions } from './constants';
import { Heading } from './types';

export const getHeadingsPending = () => action(Actions.GET_HEADINGS_PENDING);

export const getHeadingsSuccess = (headings) => action(Actions.GET_HEADINGS_SUCCESS, headings);

export const getHeadingsError = (error) => action(Actions.GET_HEADINGS_ERROR, error);

export const addHeadingPending = () => action(Actions.ADD_HEADING_PENDING);

export const addHeadingSuccess = (heading) => action(Actions.ADD_HEADING_SUCCESS, heading);

export const addHeadingError = (error) => action(Actions.ADD_HEADING_ERROR, error);

export const editHeadingPending = () => action(Actions.EDIT_HEADING_PENDING);

export const editHeadingSuccess = (heading: Heading, id: string) =>
  action(Actions.EDIT_HEADING_SUCCESS, { heading, id });

export const editHeadingError = (error) => action(Actions.EDIT_HEADING_ERROR, error);

export const disableHeadingPending = () => action(Actions.DISABLE_HEADING_PENDING);

export const disableHeadingSuccess = (heading: Heading, id: string) =>
  action(Actions.DISABLE_HEADING_SUCCESS, { heading, id });

export const disableHeadingError = (error) => action(Actions.DISABLE_HEADING_ERROR, error);

export const activateHeadingPending = () => action(Actions.ACTIVATE_HEADING_PENDING);

export const activateHeadingSuccess = (heading: Heading, id: string) =>
  action(Actions.ACTIVATE_HEADING_SUCCESS, { heading, id });

export const activateHeadingError = (error) => action(Actions.ACTIVATE_HEADING_ERROR, error);

export const deleteHeadingPending = () => action(Actions.DELETE_HEADING_PENDING);

export const deleteHeadingSuccess = (heading: Heading, id: string) =>
  action(Actions.DELETE_HEADING_SUCCESS, { heading, id });

export const deleteHeadingError = (error) => action(Actions.DELETE_HEADING_ERROR, error);
