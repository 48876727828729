import { Dispatch } from 'redux';

import {
  addResourceRequest,
  deleteResourceRequest,
  disableResourceRequest,
  editResourceRequest,
  getResourceRequest,
} from 'src/config/api';
import { translateMessages } from 'src/utils/formatters';

import { openModal } from '../ui/actions';
import { Contain } from '../ui/types';
import {
  activateHeadingError,
  activateHeadingPending,
  activateHeadingSuccess,
  addHeadingError,
  addHeadingPending,
  addHeadingSuccess,
  deleteHeadingError,
  deleteHeadingPending,
  deleteHeadingSuccess,
  disableHeadingError,
  disableHeadingPending,
  disableHeadingSuccess,
  editHeadingError,
  editHeadingPending,
  editHeadingSuccess,
  getHeadingsError,
  getHeadingsPending,
  getHeadingsSuccess,
} from './actions';
import { Heading } from './types';

export const getHeadings = (limit = null, page = null, isActive = null) => {
  return async (dispatch: Dispatch) => {
    dispatch(getHeadingsPending());
    try {
      const path = limit
        ? `/heading?limit=${limit}&page=${page}&isActive=${isActive}&isDeleted=false`
        : '/heading';
      const response = await getResourceRequest(path);
      if (response.data) {
        dispatch(
          getHeadingsSuccess({
            data: response.data,
            nextPage: response.nextPage,
            prevPage: response.prevPage,
            totalPage: response.totalPages,
            currentPage: response.page,
            limit: response.limit,
          }),
        );
      }
    } catch (error) {
      dispatch(getHeadingsError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const addHeading = (heading: Heading) => {
  return async (dispatch: Dispatch) => {
    dispatch(addHeadingPending());
    try {
      const response = await addResourceRequest('/heading', heading);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Rubro agregado',
              text: 'El nuevo rubro fue agregado con éxito.',
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        dispatch(addHeadingSuccess(response.data));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translateMessages(error.message),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      dispatch(addHeadingError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const editHeading = (options: { body: Heading; id: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(editHeadingPending());
    try {
      const response = await editResourceRequest('/heading', options);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Rubro editado',
              text: 'El rubro fue editado con éxito.',
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(editHeadingSuccess(response.data, options.id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translateMessages(error.message),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(editHeadingError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const disableHeading = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(disableHeadingPending());
    try {
      const response = await disableResourceRequest('/heading/disable', id);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Rubro desactivado',
              text: `El rubro ${response.data.name} (${response.data.currency}) fue desactivado con éxito.`,
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(disableHeadingSuccess(response.data, id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translateMessages(error.message),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(disableHeadingError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const activateHeading = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(activateHeadingPending());
    try {
      const response = await disableResourceRequest('/heading/activate', id);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Rubro Activado',
              text: `El rubro ${response.data.name} (${response.data.currency}) fue activado con éxito.`,
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(activateHeadingSuccess(response.data, id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translateMessages(error.message),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(activateHeadingError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const deleteHeading = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteHeadingPending());
    try {
      const response = await deleteResourceRequest('/heading/delete', id);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Rubro eliminado',
              text: `El rubro ${response.data.name} (${response.data.currency}) fue eliminado con éxito.`,
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(deleteHeadingSuccess(response.data, id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translateMessages(error.message),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(deleteHeadingError({ message: error.message, errorType: error.errorType }));
    }
  };
};
