import { action } from 'typesafe-actions';

import { Actions } from './constants';
import { Employee } from './types';
export const getEmployeesPending = () => action(Actions.GET_EMPLOYEES_PENDING);

export const getEmployeesSuccess = (employees) => action(Actions.GET_EMPLOYEES_SUCCESS, employees);

export const getEmployeesError = (error) => action(Actions.GET_EMPLOYEES_ERROR, error);

export const addEmployeePending = () => action(Actions.ADD_EMPLOYEE_PENDING);

export const addEmployeeSuccess = (employee) => action(Actions.ADD_EMPLOYEE_SUCCESS, employee);

export const addEmployeeError = (error) => action(Actions.ADD_EMPLOYEE_ERROR, error);

export const editEmployeePending = () => action(Actions.EDIT_EMPLOYEE_PENDING);

export const editEmployeeSuccess = (employee: Employee, id: string) =>
  action(Actions.EDIT_EMPLOYEE_SUCCESS, { employee, id });

export const editEmployeeError = (error) => action(Actions.EDIT_EMPLOYEE_ERROR, error);

export const disableEmployeePending = () => action(Actions.DISABLE_EMPLOYEE_PENDING);

export const disableEmployeeSuccess = (employee: Employee, id: string) =>
  action(Actions.DISABLE_EMPLOYEE_SUCCESS, { employee, id });

export const disableEmployeeError = (error) => action(Actions.DISABLE_EMPLOYEE_ERROR, error);

export const activateEmployeePending = () => action(Actions.ACTIVATE_EMPLOYEE_PENDING);

export const activateEmployeeSuccess = (employee: Employee, id: string) =>
  action(Actions.ACTIVATE_EMPLOYEE_SUCCESS, { employee, id });

export const activateEmployeeError = (error) => action(Actions.ACTIVATE_EMPLOYEE_ERROR, error);
