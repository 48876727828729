import { Reducer } from 'react';

import { Actions } from './constants';
import { ActionsType, State } from './types';

const initialState: State = {
  list: [],
  isLoading: false,
  prevPage: null,
  nextPage: null,
  totalPage: null,
  currentPage: null,
  limit: null,
  error: undefined,
};

const headingsReducer: Reducer<State, ActionsType> = (state = initialState, action): State => {
  switch (action.type) {
    case Actions.GET_HEADINGS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.GET_HEADINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.data,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPage: action.payload.totalPage,
        currentPage: action.payload.currentPage,
        limit: action.payload.limit,
      };
    case Actions.GET_HEADINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    case Actions.ADD_HEADING_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.ADD_HEADING_SUCCESS:
      return {
        ...state,
        list: state.limit === state.list.length ? [...state.list] : [...state.list, action.payload],
        nextPage:
          state.limit === state.list.length && !state.nextPage
            ? (state.nextPage = state.currentPage + 1)
            : state.nextPage,
        isLoading: false,
        error: undefined,
      };
    case Actions.ADD_HEADING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    case Actions.EDIT_HEADING_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.EDIT_HEADING_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, ...action.payload.heading };
          }
          return item;
        }),
        isLoading: false,
        error: undefined,
      };
    case Actions.EDIT_HEADING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };
    case Actions.DISABLE_HEADING_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.DISABLE_HEADING_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, ...action.payload.heading };
          }
          return item;
        }),
        isLoading: false,
        error: undefined,
      };
    case Actions.DISABLE_HEADING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };
    case Actions.ACTIVATE_HEADING_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.ACTIVATE_HEADING_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, ...action.payload.heading };
          }
          return item;
        }),
        isLoading: false,
        error: undefined,
      };
    case Actions.ACTIVATE_HEADING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };

    case Actions.DELETE_HEADING_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.DELETE_HEADING_SUCCESS:
      return {
        ...state,
        list: state.list.filter((item) => item._id !== action.payload.id),
        isLoading: false,
        error: undefined,
      };
    case Actions.DELETE_HEADING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };

    default:
      return state;
  }
};

export default headingsReducer;
