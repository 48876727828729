import React from 'react';

const HeaderLogo = (): JSX.Element => {
  return (
    <div>
      <img src={`${process.env.PUBLIC_URL}/assets/images/headerLogo.svg`} />
    </div>
  );
};

export default HeaderLogo;
