import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { PreviewProps } from './types';

const Preview = (props: PreviewProps) => {
  const { data, split } = props;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    containerMain: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: split ? 'column' : 'row',
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      width: split ? '150px' : '100%',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#373867',
      padding: '6px',
    },
    fontStyle: {
      fontSize: '12px',
    },
    textHeader: {
      width: split ? '150px' : '100%',
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      padding: '6px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: split ? 'auto' : '45px',
      backgroundColor: '#373867',
      justifyContent: 'center',
      padding: '10px',
    },
    values: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: split ? 'auto' : '30px',
      padding: '10px',
      justifyContent: 'center',
    },
  });

  const splitArray = (array, maxLength) => {
    if (array.length <= maxLength) {
      return [array];
    }

    return array.reduce((result, element) => {
      const lastArray = result[result.length - 1];
      if (!lastArray || lastArray.length === maxLength) {
        result.push([element]);
      } else {
        lastArray.push(element);
      }
      return result;
    }, []);
  };
  const maxLength = 6;

  const dataToPrint = splitArray(data, maxLength);

  return (
    <div style={styles.container}>
      {split ? (
        dataToPrint.map((data, index) => {
          return (
            <View key={index}>
              <View style={styles.header}>
                {data.map((header, index) => {
                  return (
                    <View style={styles.textHeader} key={index}>
                      <Text style={styles.fontStyle}>{header.header}</Text>
                    </View>
                  );
                })}
              </View>
              <View style={styles.values}>
                {data.map((value, index) => {
                  return (
                    <View style={styles.text} key={index}>
                      <Text style={styles.fontStyle}>{value.value}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })
      ) : (
        <>
          {data.map((preview, index) => {
            return (
              <div style={styles.containerMain} key={index}>
                <div style={styles.header}>
                  <div style={styles.textHeader}>
                    <p style={styles.fontStyle}>{preview.header}</p>
                  </div>
                </div>
                <div style={styles.values}>
                  <div style={styles.text}>
                    <p style={styles.fontStyle}>{preview.value}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Preview;
