import { ActionType } from 'typesafe-actions';

import * as actions from './actions';

export interface Heading {
  _id?: string;
  name: string;
  description?: string;
  onlyLiquidate: boolean;
  categories: CategoryData[];
  operation: OperationType;
  currency: CurrencyType;
  isActive: boolean;
  isDeleted: boolean;
}

export enum OperationType {
  SUM = 'SUM',
  REST = 'REST',
}

export enum CurrencyType {
  USD = 'USD',
  ARS = 'ARS',
  UY = 'UY',
}

export interface CategoryData {
  _id?: string;
  description: string;
  name: string;
}

export interface State {
  list: Heading[];
  isLoading: boolean;
  prevPage: any;
  nextPage: any;
  totalPage: any;
  currentPage: any;
  error: string;
  limit: any;
}

export type ActionsType = ActionType<typeof actions>;
