import { RoleType } from 'src/redux/users/types';

export const navbarItems = [
  { path: '/admin-al/liquidations', name: 'LIQUIDACIONES', role: [RoleType.ADMIN_AL] },
  { path: '/admin-al/histories', name: 'HISTÓRICO', role: [RoleType.ADMIN_AL] },
  { path: '/admin-al/headings', name: 'RUBROS', role: [RoleType.ADMIN_AL] },
  { path: '/admin-al/employees', name: 'EMPLEADOS', role: [RoleType.ADMIN_AL] },
];

export const navbarItemsDraft = [
  { path: '/admin-ad/drafts', name: 'DRAFTS', role: [RoleType.ADMIN_AD] },
  { path: '/admin-ad/histories', name: 'HISTÓRICO', role: [RoleType.ADMIN_AD] },
];

export const operationItems = {
  SUM: 1,
  REST: -1,
};
