export interface FormValues {
  _id?: string;
  name: string;
  description?: string;
  onlyLiquidate: boolean;
  categories: CategoryData[] | CategoryInput[];
  operation: OperationType;
  currency: CurrencyType;
  isActive: boolean;
}

export enum OperationType {
  SUM = 'SUM',
  REST = 'REST',
}

export enum CurrencyType {
  USD = 'USD',
  ARS = 'ARS',
  UY = 'UY',
}

export interface CategoryData {
  _id?: string;
  description: string;
  name: string;
}

export interface CategoryInput {
  id: string;
  label: string;
}
export interface HeadingFormModalProps {
  id?: string;
  title?: string;
  limit?: any;
  currentPage?: any;
  isActive?: any;
}
