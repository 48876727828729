export enum Actions {
  GET_HEADINGS_PENDING = 'GET_HEADINGS_PENDING',
  GET_HEADINGS_SUCCESS = 'GET_HEADINGS_SUCCESS',
  GET_HEADINGS_ERROR = 'GET_HEADINGS_ERROR',
  ADD_HEADING_SUCCESS = 'ADD_HEADING_SUCCESS',
  ADD_HEADING_PENDING = 'ADD_HEADING_PENDING',
  ADD_HEADING_ERROR = 'ADD_HEADING_ERROR',
  EDIT_HEADING_PENDING = 'EDIT_HEADING_PENDING',
  EDIT_HEADING_SUCCESS = 'EDIT_HEADING_SUCCESS',
  EDIT_HEADING_ERROR = 'EDIT_HEADING_ERROR',
  DISABLE_HEADING_PENDING = 'DISABLE_HEADING_PENDING',
  DISABLE_HEADING_SUCCESS = 'DISABLE_HEADING_SUCCESS',
  DISABLE_HEADING_ERROR = 'DISABLE_HEADING_ERROR',
  ACTIVATE_HEADING_PENDING = 'ACTIVATE_HEADING_PENDING',
  ACTIVATE_HEADING_SUCCESS = 'ACTIVATE_HEADING_SUCCESS',
  ACTIVATE_HEADING_ERROR = 'ACTIVATE_HEADING_ERROR',
  DELETE_HEADING_PENDING = 'DELETE_HEADING_PENDING',
  DELETE_HEADING_SUCCESS = 'DELETE_HEADING_SUCCESS',
  DELETE_HEADING_ERROR = 'DELETE_HEADING_ERROR',
}
