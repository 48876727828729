export interface UserData {
  _id?: string;
  firebaseUid?: string;
  name: string;
  email: string;
  role: RoleType;
  isActive: boolean;
}

export enum RoleType {
  ADMIN_AD = 'ADMIN_AD',
  ADMIN_AL = 'ADMIN_AL',
  ROLE_EMPTY = '',
}
