import axios, { AxiosResponse } from 'axios';

import { getCurrentFirebaseToken } from 'src/helper/firebase';
import { ErrorFormat, ErrorType } from 'src/interfaces/axios-types';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async (req) => {
    const token = await getCurrentFirebaseToken();
    if (token) {
      req.headers['token'] = token;
    }
    return req;
  },
  (error) => {
    Promise.reject(error);
  },
);

const responseBody = (response: AxiosResponse) => response.data;

const parseError = async (error) => {
  if (error.response.status === 401) {
    throw {
      message: error.response.data.message,
      errorType: ErrorType.NETWORK_ERROR,
    };
  }

  if (error.response.status === 403) {
    throw {
      message: error.response.data.message,
      errorType: ErrorType.AUTH_ERROR,
    };
  }
  throw {
    message: error.response.data.message,
    errorType: ErrorType.CLIENT_ERROR,
  };
};

export const getResourceRequest = <T>(apiRoute) =>
  api.get<T[]>(apiRoute).then(responseBody).catch<ErrorFormat>(parseError);

export const addResourceRequest = <T>(apiRoute, body: T) =>
  api.post<T>(apiRoute, body).then(responseBody).catch<ErrorFormat>(parseError);

export const editResourceRequest = <T>(apiRoute, options: { body: T; id: string }) =>
  api
    .put<T>(`${apiRoute}/${options.id}`, options.body)
    .then(responseBody)
    .catch<ErrorFormat>(parseError);

export const disableResourceRequest = <T>(apiRoute, id: string) =>
  api.patch<T>(`${apiRoute}/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export const deleteResourceRequest = <T>(apiRoute, id: string) =>
  api.delete<T>(`${apiRoute}/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export const patchResourceRequest = <T>(apiRoute, options: { body: T; id: string }) =>
  api
    .patch<T>(`${apiRoute}/${options.id}`, options.body)
    .then(responseBody)
    .catch<ErrorFormat>(parseError);

export const editMassivelyResourceRequest = <T>(apiRoute, body: T) =>
  api.put<T>(apiRoute, body).then(responseBody).catch<ErrorFormat>(parseError);

export const createResourceRequest = <T>(apiRoute) =>
  api.post<T>(apiRoute).then(responseBody).catch<ErrorFormat>(parseError);

export default api;
