import { Reducer } from 'react';

import { Actions } from './constants';
import { ActionsType, State } from './types';

const initialState: State = {
  list: [],
  prevPage: null,
  nextPage: null,
  totalPage: null,
  currentPage: null,
  isLoading: false,
  limit: null,
  error: undefined,
  newEmployee: false,
};

const employeesReducer: Reducer<State, ActionsType> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_EMPLOYEES_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.data,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nexPage,
        totalPage: action.payload.totalPage,
        currentPage: action.payload.currentPage,
        limit: action.payload.limit,
      };
    case Actions.GET_EMPLOYEES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    case Actions.ADD_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoading: true,
        newEmployee: false,
      };
    case Actions.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        list: state.limit === state.list.length ? [...state.list] : [...state.list, action.payload],
        nextPage:
          state.limit === state.list.length && !state.nextPage
            ? (state.nextPage = state.currentPage + 1)
            : state.nextPage,
        isLoading: false,
        error: undefined,
        newEmployee: true,
      };
    case Actions.ADD_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    case Actions.EDIT_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoading: true,
        newEmployee: false,
      };
    case Actions.EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, ...action.payload.employee };
          }
          return item;
        }),
        isLoading: false,
        error: undefined,
        newEmployee: true,
      };
    case Actions.EDIT_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };
    case Actions.DISABLE_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.DISABLE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, ...action.payload.employee };
          }
          return item;
        }),
        isLoading: false,
        error: undefined,
      };
    case Actions.DISABLE_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };
    case Actions.ACTIVATE_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.ACTIVATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, ...action.payload.employee };
          }
          return item;
        }),
        isLoading: false,
        error: undefined,
      };
    case Actions.ACTIVATE_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload },
      };

    default:
      return state;
  }
};

export default employeesReducer;
