export enum Actions {
  GET_LIQUIDATIONS_PENDING = 'GET_LIQUIDATIONS_PENDING',
  GET_LIQUIDATIONS_SUCCESS = 'GET_LIQUIDATIONS_SUCCESS',
  GET_LIQUIDATIONS_ERROR = 'GET_LIQUIDATIONS_ERROR',
  CONFIRM_LIQUIDATION_PENDING = 'CONFIRM_LIQUIDATION_PENDING',
  CONFIRM_LIQUIDATION_SUCCESS = 'CONFIRM_LIQUIDATION_SUCCESS',
  CONFIRM_LIQUIDATION_ERROR = 'CONFIRM_LIQUIDATION_ERROR',
  CREATE_LIQUIDATION_PENDING = 'CREATE_LIQUIDATION_PENDING',
  CREATE_LIQUIDATION_SUCCESS = 'CREATE_LIQUIDATION_SUCCESS',
  CREATE_LIQUIDATION_ERROR = 'CREATE_LIQUIDATION_ERROR',
}
