import { action } from 'typesafe-actions';

import { Actions } from './constants';
import { Category } from './types';

export const getCategoriesPending = () => action(Actions.GET_CATEGORIES_PENDING);

export const getCategoriesSuccess = (categories: Category[]) =>
  action(Actions.GET_CATEGORIES_SUCCESS, categories);

export const getCategoriesError = (error) => action(Actions.GET_CATEGORIES_ERROR, error);
