import { Dispatch } from 'redux';

import { getResourceRequest } from 'src/config/api';

import { getCategoriesError, getCategoriesPending, getCategoriesSuccess } from './actions';

export const getCategories = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getCategoriesPending());
    try {
      const response = await getResourceRequest('/category');
      if (response.data?.length) {
        dispatch(getCategoriesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getCategoriesError({ message: error.message, errorType: error.errorType }));
    }
  };
};
