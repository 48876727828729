import { Dispatch } from 'redux';
import translate from 'translate';

import {
  addResourceRequest,
  disableResourceRequest,
  editResourceRequest,
  getResourceRequest,
} from 'src/config/api';

import { openModal } from '../ui/actions';
import { Contain } from '../ui/types';
import {
  activateEmployeeError,
  activateEmployeePending,
  activateEmployeeSuccess,
  addEmployeeError,
  addEmployeePending,
  addEmployeeSuccess,
  disableEmployeeError,
  disableEmployeePending,
  disableEmployeeSuccess,
  editEmployeeError,
  editEmployeePending,
  editEmployeeSuccess,
  getEmployeesError,
  getEmployeesPending,
  getEmployeesSuccess,
} from './actions';
import { Employee } from './types';

export const getEmployees = (limit = null, page = null, isActive = null) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployeesPending());
    try {
      const path = limit
        ? `/employee?limit=${limit}&page=${page}&isActive=${isActive}`
        : '/employee';
      const response = await getResourceRequest(path);
      if (response.data) {
        dispatch(
          getEmployeesSuccess({
            data: response.data,
            nexPage: response.nextPage,
            prevPage: response.prevPage,
            totalPage: response.totalPages,
            currentPage: response.page,
            limit: response.limit,
          }),
        );
      }
    } catch (error) {
      dispatch(getEmployeesError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const addEmployee = (employee: Employee) => {
  return async (dispatch: Dispatch) => {
    dispatch(addEmployeePending());
    try {
      const response = await addResourceRequest('/employee', employee);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Empleado agregado',
              text: await translate(`${response.message}`, { to: 'es' }),
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        dispatch(addEmployeeSuccess(response.data));
        getEmployees();
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema',
            text: await translate(`${error.message}`, { to: 'es' }),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      dispatch(addEmployeeError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const editEmployee = (options: { body: Employee; id: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(editEmployeePending());
    try {
      const response = await editResourceRequest('/employee', options);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Empleado editado',
              text: await translate(`${response.message}`, { to: 'es' }),
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(editEmployeeSuccess(response.data, options.id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translate(`${error.message}`, { to: 'es' }),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(editEmployeeError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const disableEmployee = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(disableEmployeePending());
    try {
      const response = await disableResourceRequest('/employee/disable', id);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Empleado desactivado',
              text: await translate(`${response.message}`, { to: 'es' }),
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(disableEmployeeSuccess(response.data, id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translate(`${error.message}`, { to: 'es' }),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(disableEmployeeError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const activateEmployee = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(activateEmployeePending());
    try {
      const response = await disableResourceRequest('/employee/activate', id);
      if (!response.error) {
        dispatch(
          openModal(
            {
              title: 'Empleado Activado',
              text: await translate(`${response.message}`, { to: 'es' }),
              success: true,
            },
            Contain.ALERT_MODAL,
          ),
        );
        return dispatch(activateEmployeeSuccess(response.data, id));
      }
    } catch (error) {
      dispatch(
        openModal(
          {
            title: 'Hubo un problema.',
            text: await translate(`${error.message}`, { to: 'es' }),
            warning: true,
          },
          Contain.ALERT_MODAL,
        ),
      );
      return dispatch(
        activateEmployeeError({ message: error.message, errorType: error.errorType }),
      );
    }
  };
};
