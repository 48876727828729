import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import firebaseApp from 'src/helper/firebase';
import { RootState } from 'src/redux/store';

import Loading from '../shared/ui/loading';
import styles from './layout.module.css';

const Layout = (): JSX.Element => {
  const { isLoading } = useSelector((state: RootState) => state.auth);

  const authError = useSelector((state: RootState) => state.auth.authError);

  const handleLogout = async () => {
    try {
      await firebaseApp.auth().signOut();
    } catch (error) {
      console.error(error);
    }
  };

  authError && handleLogout();

  return (
    <div className={styles.container}>
      {isLoading && <Loading show={isLoading} />}
      <Outlet />
    </div>
  );
};

export default Layout;
