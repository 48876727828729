import { Reducer } from 'react';

import { Actions } from './constants';
import { ActionsType, State } from './types';

const initialState: State = {
  list: [],
  isLoading: false,
  error: undefined,
};

const paymentReducer: Reducer<State, ActionsType> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.EDIT_PAYMENTS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.EDIT_PAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    case Actions.EDIT_PAYMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
        list: [],
      };

    default:
      return state;
  }
};

export default paymentReducer;
