import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { AutocompleteChip, Button, RadioButton, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import Dropdown from 'src/components/shared/ui/dropdown';
import { getCategories } from 'src/redux/categories/thunk';
import { addHeading, editHeading, getHeadings } from 'src/redux/headings/thunk';
import { CurrencyType, OperationType } from 'src/redux/headings/types';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { closeModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

import { currency, onlyLiquidate, operation } from '../constants';
import styles from './heading-form.module.css';
import { FormValues, HeadingFormModalProps } from './types';
import { headingValidation } from './validations';

const HeadingForm = (props: HeadingFormModalProps) => {
  const dispatch: AppDispatch<null> = useAppDispatch();

  const { id, title, limit, currentPage, isActive } = props;

  const categories = useAppSelector((state: RootState) => state.category.list);

  const headingList = useAppSelector((state: RootState) => state.headings.list);

  const [categoriesList, setCategoriesList] = useState([]);

  const [heading, setHeading] = useState({} as FormValues);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getHeadings(limit, currentPage, isActive));
  }, [currentPage, dispatch, isActive, limit]);

  useEffect(() => {
    const categoriesMap = categories.map((category) => {
      return {
        id: category._id,
        label: category.name,
      };
    });
    setCategoriesList(categoriesMap);
  }, [categories]);

  useEffect(() => {
    if (id) {
      const headingFind = headingList.find((heading) => heading._id === id);
      setHeading(headingFind);
    }
  }, [headingList, id]);

  useEffect(() => {
    if (Object.keys(heading).length) {
      reset({
        name: heading.name,
        description: heading.description,
        onlyLiquidate: heading.onlyLiquidate,
        categories: heading.categories.map((category) => {
          return {
            id: category._id,
            label: category.name,
          };
        }),
        operation: heading.operation,
        currency: heading.currency,
        isActive: heading.isActive,
      });
    }
  }, [heading]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      onlyLiquidate: false,
      categories: [],
      operation: OperationType.REST,
      currency: CurrencyType.ARS,
      isActive: true,
    },
    mode: 'onBlur',
    resolver: joiResolver(headingValidation()),
  });

  const onSubmit = async (data) => {
    const categoriesId = data.categories.map((category) => category.id);
    const options = { id: id, body: { ...data, categories: categoriesId } };
    if (id) {
      data = {
        ...data,
        categories: categoriesId,
      };
      await dispatch(editHeading(options));
    } else {
      data = {
        ...data,
        categories: categoriesId,
      };
      await dispatch(addHeading(data));
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.containerInputs}>
          <div className={styles.firstContain}>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="Nombre"
                name="name"
                type={'text'}
                error
                fullWidth
              />
              <Dropdown
                control={control}
                testId={'currency-dropdown'}
                label="Divisa"
                name="currency"
                options={currency}
                error
                fullWidth
              />
            </div>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="Descripción"
                name="description"
                type={'text'}
                error
                fullWidth
                multiline
                rows={5}
                data-testid={'heading-input-description'}
              />
            </div>
          </div>
          <div className={styles.secondContainer}>
            <div className={styles.firstRadioContainer}>
              <h4>Operación</h4>
              <RadioButton
                testId={'operation-radio-button'}
                name={'operation'}
                label={'Operación'}
                options={operation}
                control={control}
                className={styles.radioButton}
              />
            </div>
            <div className={styles.secondRadioContainer}>
              <h4>Solo liquidación</h4>
              <RadioButton
                testId={'only-liquidate-radio-button'}
                name={'onlyLiquidate'}
                label={'Solo liquidación'}
                options={onlyLiquidate}
                control={control}
                className={styles.radioButton}
              />
            </div>
          </div>
        </div>
        <div className={styles.categories}>
          <AutocompleteChip
            control={control}
            name="categories"
            items={categoriesList}
            label={'Categorías'}
            testId={'heading-categories-input'}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            testId="cancel-heading-button"
            materialVariant={Variant.OUTLINED}
            label="Cancelar"
            onClick={() => dispatch(closeModal())}
          />
          <Button
            testId="submit-heading-button"
            materialVariant={Variant.CONTAINED}
            label="Confirmar"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default HeadingForm;
