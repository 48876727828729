import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Layout from 'src/components/layout';
import { Header } from 'src/components/shared/common';
import { Modal } from 'src/components/shared/ui';
import Loading from 'src/components/shared/ui/loading';
import { tokenListener } from 'src/helper/firebase';
import { UiRoutes } from 'src/interfaces/routes-types';
import { RootState, useAppSelector } from 'src/redux/store';
import { closeModal } from 'src/redux/ui/actions';
import { RoleType } from 'src/redux/users/types';
import { AppDispatch } from 'src/types';

import styles from '../components/layout/layout.module.css';

const Login = lazy(() => import('src/components/pages/login'));
const PrivateRoute = lazy(() => import('src/components/shared/common/private-route'));
const NotAllowed = lazy(() => import('src/components/pages/not-allowed'));
const AdminAd = lazy(() => import('./admin-ad'));
const AdminAl = lazy(() => import('./admin-al'));

const AppRoutes = () => {
  const dispatch: AppDispatch<null> = useDispatch();
  const showModal = useAppSelector((state: RootState) => state.ui.configModal.showModal);
  const accessRole = useAppSelector((state: RootState) => state.auth.authUser.role);
  const isFirebaseInitializing = useAppSelector(
    (state: RootState) => state.auth.firebaseInitializing,
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const location = useLocation();

  const globalState = useAppSelector((state: RootState) => state);

  const redirectPath = (role) => {
    let path = '/login';
    switch (role) {
      case RoleType.ADMIN_AD:
        path = '/admin-ad/drafts';
        break;
      case RoleType.ADMIN_AL:
        path = '/admin-al/liquidations';
        break;
      default:
        path = '/login';
    }
    return path;
  };

  useEffect(() => {
    if (accessRole) {
      pathname === '/' || pathname === '/not-allowed'
        ? navigate(redirectPath(accessRole))
        : navigate(pathname);
    }
  }, [accessRole, navigate, pathname]);

  useEffect(() => {
    tokenListener();
  }, []);

  useEffect(() => {
    dispatch(closeModal());
  }, [dispatch, location]);

  const checkLoading = (state: RootState): boolean => {
    if (!state) {
      return false;
    }

    const keys = Object.keys(state);

    for (let i = 0; i < keys.length; i++) {
      const value = state[keys[i]];

      if (value && value.isLoading === true) {
        return true;
      }
    }
    return false;
  };

  const isLoading = checkLoading(globalState);

  if (isFirebaseInitializing) return <Loading show={true} />;

  return (
    <div className={styles.container}>
      <Suspense fallback={<Loading show={isLoading} />}>
        <Modal testId="uniqueModal" isOpen={showModal} onClose={() => dispatch(closeModal())} />
        <Loading show={isLoading} />
        {accessRole && <Header />}
        <Routes>
          <Route element={<Layout />}>
            <Route path={UiRoutes.LOGIN} element={<Login />} />
            <Route
              path={`${UiRoutes.ADMIN_AL}/*`}
              element={
                <PrivateRoute role={[RoleType.ADMIN_AL]}>
                  <AdminAl />
                </PrivateRoute>
              }
            />
            <Route
              path={`${UiRoutes.ADMIN_AD}/*`}
              element={
                <PrivateRoute role={[RoleType.ADMIN_AD]}>
                  <AdminAd />
                </PrivateRoute>
              }
            />
            <Route path={UiRoutes.NOT_ALLOWED} element={<NotAllowed />} />
            <Route path="/*" element={<Navigate to={redirectPath(accessRole)} />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRoutes;
